import React, { Component, Fragment } from "react";

// import { Header } from './Common/Header';
import { Timer } from "./Components/Timer";
import TextInput from "./Components/TextInput";
import Card from "./Components/Card";
import Button from "./Components/Button";
import { get } from "axios";
// import io from 'socket.io-client';
import "./App.css";
import mobile from "./img/hanging-mob.png";
import ultrasound from "./img/ultrasound.jpeg";
class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			name: "",
			clave: "",
			inputValue: "",
			authenticated: false,
			invalidKey: false,
			loading: false,
			showSurprise: false
		};
		this.sendUser = this.sendUser.bind(this);
		this.setClave = this.setClave.bind(this);
	}

	componendDidMount() {
		const currentKey = localStorage.getItem("clave");
		if (currentKey) {
			this.setState({ loading: true });
			get("/api/users", { params: { clave: currentKey } }).then(resp => {
				this.setState(prevState => {
					const test = { clave: currentKey, showSurprise: true };
					const t = Object.assign(
						{},
						prevState,
						{ user: resp.data },
						{ loading: false },
						test
					);
					return t;
				});
			});
		} else {
			this.setState({ loading: false });
		}

		// this.socket = io();
		// this.socket.on('user already exists', resp => this.askUser(resp));
		// this.socket.on(s'user authenticated', resp => this.setUser(resp));
		// this.socket.on('all users connected', resp => this.showSurprise(resp));
		// get('/users').then(resp => {
		//   console.log(resp);
		// });
	}
	setClave(e) {
		this.setState({ clave: e.target.value.toUpperCase() });
	}

	sendUser(e) {
		e.preventDefault();
		const key = this.state.clave;
		get("/api/users", { params: { clave: key } })
			.then(resp => {
				localStorage.setItem("clave", this.state.clave);
				this.setState({ showSurprise: true, loading: true, user: resp.data });
				setTimeout(() => {
					this.setState({ loading: false });
					localStorage.setItem("clave", this.state.clave);
				}, 3000);
			})
			.catch(e => this.setState({ invalidKey: true }));
		// this.socket.emit('user connected', this.state.inputValue.toLowerCase());
	}

	render() {
		const { showSurprise, loading, user, invalidKey, clave } = this.state;
		return loading ? (
			<div className="loader" />
		) : showSurprise ? (
			<div className="test">
				<img src={mobile} alt="mobile" />
				<h2 className="App-intro">¡¡ {user.name ? user.name : null} !!</h2>
				<h2 className="App-intro">¡¡ {user.msg ? user.msg : null} !!</h2>
				<p className="connector">En</p>
				<Timer date={"2018-12-15T00:00:00"}>
					<p>
						{"\u2764"}
						¡¡
						{`${
							user.isMultiple !== undefined && user.isMultiple ? "Los" : "Te"
						} amamos`}
						!!
						{"\u2764"}
					</p>
				</Timer>
				<img src={ultrasound} alt="ultrasound" />
			</div>
		) : (
			<Fragment>
				<Card>
					<form onSubmit={this.sendUser}>
						<TextInput onChange={this.setClave} placeholder="Clave" />
						{invalidKey ? <span className="error">Checa la clave</span> : null}
						<Button disabled={clave === ""} type="submit">
							Entrar
						</Button>
					</form>
				</Card>
			</Fragment>
		);
	}
}

export default App;
