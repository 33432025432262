import React from 'react';
import './TextInput.css';

const TextInput = props => (
  <input
    type="text"
    onChange={props.onChange}
    className="input--text"
    placeholder={props.placeholder}
  />
);

export default TextInput;
